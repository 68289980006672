<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    light
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    color="#f1f2f6"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <!-- <div 
style="
     background: url(/media/user-bg.jpg) no-repeat; height:140px;
 
" > -->

    <div>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title
            class="text-uppercase font-weight-regular display-2"
          >
            <span class="logo-normal">
              <router-link to="/">
                <img src="/media/logo.png" alt="Logo" width="180" />
              </router-link>
            </span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <div v-if="$acl.check('isSeller')" :key="componentKey"></div>

      <div v-if="$acl.check('isSupplier')" :key="componentKey">
        <template v-for="(item2, i) in computedItem2s">
          <template v-if="item2.children">
            <base-item-group :key="`group-${i}`" class="drawer" :item="item2">
              <!--  -->
            </base-item-group>
          </template>

          <template v-else>
            <template v-if="item2.title != 'Messaging'">
              <base-item :key="`item-${i}`" :item="item2" />
            </template>
            <template v-else>
              <v-list-item :key="i" :to="item2.to">
                <v-list-item-icon>
                  <v-badge :value="notifications" color="red" overlap bordered>
                    <v-icon v-text="item2.icon"></v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item2.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </template>
        </template>
        <template v-if="admin == true">
          <v-list-item :to="addUser.to">
            <v-list-item-icon>
              <v-icon v-text="addUser.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ addUser.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    componentKey: 0,

    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/',
      },

      {
        icon: 'mdi-notebook',
        title: 'Your Profile',
        to: '/buyer/profile-editor',
      },

      {
        icon: 'mdi-chart-timeline-variant',
        title: 'Discover Suppliers',
        to: '/seller/company-list',
      },
      {
        icon: 'mdi-book',
        title: 'My Applications',
        to: '/buyer/applications',
      },

      {
        icon: 'mdi-cart',
        title: 'Marketplace',
        to: '/market/home',
      },

      {
        icon: 'mdi-comment-text-outline',
        title: 'Messaging',
        to: '/seller/messaging',
      },

      {
        icon: 'mdi-cog',
        title: 'Purchasing Settings',
        to: '/market/settings',
      },

      {
        group: '/store',
        icon: 'mdi-receipt',
        title: 'Orders & Invoices',
        children: [
          {
            title: 'Orders',
            to: 'orders',
          },
          {
            title: 'Invoices',
            to: 'invoices',
          },
          {
            title: 'Shipments',
            to: 'shipments',
          },
        ],
      },
      {
        icon: 'mdi-receipt',
        title: 'Billing Center',
        to: '/billing',
      },
    ],

    item2s: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        to: '/',
      },

      {
        icon: 'mdi-account-box',
        title: 'Build Your Profile',
        to: '/seller/profile-editor',
      },

      {
        group: '/crm',
        icon: 'mdi-view-comfy',
        title: 'Partner Tools',
        children: [
          {
            title: 'Application Builder',
            to: 'applicationbuilder',
          },
          {
            title: 'Applications List',
            to: 'applications',
          },
          {
            title: 'Partner List',
            to: 'partners',
          },

         
        ],
      },
      {
        group: '/pim',
        icon: 'mdi-clipboard-outline',
        title: 'Product Data',

        children: [
           {
            title: 'Products',
            to: 'list',
          },
           {
            title: 'Assortments',
            to: 'assortments',
          },
          {
            title: 'Catalogs',
            to: 'channels',
          },
          {
            title: 'Price Lists',
            to: 'pricing',
          },
          
         
         
        ],
      },
      {
        group: '/market',
        icon: 'mdi-cart',
        title: 'Marketplace',
        children: [
          {
            title: 'Orders',
            to: 'order-list',
          },
          {
            title: 'Invoices',
            to: 'invoice-list',
          },
          {
            title: 'Shipments',
            to: 'shipment-list',
          },
          {
            title: 'Payouts',
            to: 'payouts',
          },
          {
            title: 'Settings',
            to: 'seller-settings',
          },
        ],
      },

      {
        icon: 'mdi-comment-text-outline',
        title: 'Messaging',
        to: '/supplier/messaging',
      },
      {
        icon: 'mdi-view-dashboard',
        title: 'Website Invite  Widget',
        to: '/seller/widget',
      },


    ],
    
    addUser: {
      icon: 'mdi-account',
      title: 'Add Users',
      to: '/admin/adduser',
    },
  }),

  computed: {
    ...mapGetters(['isAuthenticated', 'currentUser', 'roomsByAlias']),
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    admin() {
      return this.$store.state.auth.user.admin
    },
    approved() {
      return this.$store.state.auth.user.approved
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },

    computedItem2s() {
      return this.item2s.map(this.mapItem2)
    },

    computedItem3s() {
      return this.item3s.map(this.mapItem3)
    },
    notifications() {
      return Object.values(this.roomsByAlias)
        .map((r) => r._notificationCounts.total)
        .reduce((a, c) => a + c, 0)
    },
  },

  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val)
    },
  },

  methods: {
    ...mapActions(['getUserInfo']),

    async updateUnreadChats() {
      if (this.$acl.check('isSupplier')) {
        this.$apollo.queries.unreadChats.skip = false
        await this.$apollo.queries.unreadChats.refetch()
        this.notifications = this.unreadChats
      } else if (this.$acl.check('isSeller')) {
        this.$apollo.queries.unreadSellerChats.skip = false
        await this.$apollo.queries.unreadSellerChats.refetch()
        this.notifications = this.unreadSellerChats
      }
    },

    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
      }
    },

    mapItem2(item2) {
      return {
        ...item2,
        children: item2.children
          ? item2.children.map(this.mapItem2)
          : undefined,
      }
    },
    mapItem3(item3) {
      return {
        ...item3,
        children: item3.children
          ? item3.children.map(this.mapItem3)
          : undefined,
      }
    },

    mounted() {
      setTimeout(() => {
        this.componentKey += 1
      }, 200)
    },
  },
}
</script>

<style lang="sass">

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>

<style scoped>
.drawer--active {
  background: #ccc !important;
}

.theme--light .v-list-item--active {
  background: #ccc !important;
}

.v-list--nav .v-list-item :active {
  background: #ccc !important;
}

.drawer .v-list--nav .v-list-item :active {
  background: #ccc !important;
}
</style>
